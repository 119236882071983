@media only screen and (min-width: 1024px) {

  body {
    /* max-width: 1200px; */
     /* max width 1200px */ 



    color: rgb(255, 255, 255);
  }
  h1 {
    font-size: larger;
    color: rgb(0, 0, 0);

    


  }

  /* a {
    text-decoration: none;
    margin-top: auto;
    margin-bottom: auto;
    font-size: medium;
    font-weight: bold;
    color: rgb(0, 0, 0);

  } */

  /* .header {
    top:0;
    position: fixed;
    width: 100%;
    padding-left:20px;
    padding-top:10px;
    padding-bottom:10px;
    display: grid;
    grid-template-columns: 20% 60%;
    background-color: rgba(255, 255, 255, 0.159);
  } */
  .headersub {
    display: flex;
    
    justify-content: space-between;
    /* padding-right:20px; */
  }
  
  /* h3 {
    padding-top: 50px;
  } */


  
}



@media only screen and (max-width: 1024px) {
  body {
    margin:20px;
  }
  h1 {
      margin:0;
      color: white;
  }
  h2 {
      margin-top:10;
      font-size: x-large;
      color: white;
  }
}



.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  margin: 15% auto;
  padding: 20px;
  width: 50%;
  text-align: center;
}

.close {
  color: red;
  float: right;
  font-size: 24px;
  cursor: pointer;
}